export default [
  {
    header: 'Inbox / Messages (New)',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Conversations 1',
        route: 'apps-email',
        icon: 'MessageSquareIcon',
      }, {
        title: 'Conversations 2',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
      }, {
        title: 'Contacts',
        route: 'apps-users-list',
        icon: 'UsersIcon',
      }, {
        title: 'Analytics 1',
        route: 'dashboard-analytics',
        icon: 'PieChartIcon',
      }, {
        title: 'Analytics 2',
        route: 'dashboard-ecommerce',
        icon: 'PieChartIcon',
      }, {
        title: 'Analytics 3',
        route: 'table-good-table',
        icon: 'ServerIcon',
      }, {
        title: 'Analytics 4',
        route: 'table-bs-table',
        icon: 'GridIcon',
      }, {
        title: 'Charts 1',
        route: 'charts-apex-chart',
        icon: 'ServerIcon',
      }, {
        title: 'Charts 2',
        route: 'charts-chartjs',
        icon: 'GridIcon',
      }, {
        title: 'Charts 3',
        route: 'charts-echart',
        icon: 'MapIcon',

      },
    ],
  }]
